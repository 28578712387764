import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fragrance',
  templateUrl: './fragrance.component.html',
  styleUrls: ['./fragrance.component.scss'],
})
export class FragranceComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
