import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { HeaderBlueComponent } from './header-blue/header-blue.component';
import { FragranceComponent } from './fragrance/fragrance.component';
import { SubheaderTabsComponent } from './subheader-tabs/subheader-tabs.component';
import { SubheaderFiltersComponent } from './subheader-filters/subheader-filters.component';
import { SubheaderGenderFiltersComponent } from './subheader-gender-filters/subheader-gender-filters.component';
import { IngredientItemComponent } from './ingredient-item/ingredient-item.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from '../helpers/translate.helper';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderBlueComponent,
    FragranceComponent,
    SubheaderTabsComponent,
    SubheaderFiltersComponent,
    SubheaderGenderFiltersComponent,
    IngredientItemComponent,
    ProductItemComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    HeaderComponent,
    HeaderBlueComponent,
    FragranceComponent,
    SubheaderTabsComponent,
    SubheaderFiltersComponent,
    SubheaderGenderFiltersComponent,
    IngredientItemComponent,
    ProductItemComponent,
  ],
})
export class ComponentsModule {}
