import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Auth} from "./services/auth.service";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {catchError} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {ToastController} from '@ionic/angular';

@Injectable()
export class TokenInterceptor implements HttpInterceptor{
    constructor(private auth: Auth, private router: Router, private toastController:ToastController) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // If firebase DB -> modify request
        if (this.isValidRequestForInterceptor(request.url)){
            if(this.auth.isAuth()){
                request = request.clone({
                    setHeaders:{
                        Authorization: this.auth.getToken()
                    },
                    setParams: {
                        auth: this.auth.getToken()
                    }
                })
            }
            return next.handle(request).pipe(
                catchError((error: HttpErrorResponse): Observable<any>=>{
                    if(error.status === 401){
                        this.presentToast('Unauthorized')
                        this.auth.logout()
                        this.router.navigate(['/login'], {
                            queryParams:{
                                sessionExpired:true
                            }
                        })
                    }

                    return throwError(error)
                })
            )
        }

        // Default request
        return next.handle(request)
    }

    // Utils

    // Check for firebase DB
    private isValidRequestForInterceptor(requestUrl: string): boolean {
        const firebaseIndicator: string = 'firebasedatabase.app/'
        const isFrebase = requestUrl.indexOf(firebaseIndicator)
        return isFrebase > 0
    }

    async presentToast(message) {
        const toast = await this.toastController.create({
            message: message,
            duration: 2000,
            position: 'top'
        })
        await toast.present()
    }
}
