import {Component, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-subheader-filters',
  templateUrl: './subheader-filters.component.html',
  styleUrls: ['./subheader-filters.component.scss'],
})
export class SubheaderFiltersComponent implements OnInit {
  @Output() activeFilter = new EventEmitter<string>();
  items: any = [
    {
      text: 'All',
      key: 'normal',
      selected: true,
      isIcon: true,
    },
    {
      text: 'Fragrances',
      key: 'fragrances',
      selected: false,
      isIcon: true,
    },
    {
      text: 'Body',
      key: 'body',
      selected: false,
      isIcon: true,
    },
    {
      text: 'Oils',
      key: 'oils',
      selected: false,
      isIcon: true,
    },
    {
      text: 'Hair',
      key: 'hair',
      selected: false,
      isIcon: true,
    },
    {
      text: 'Home',
      key: 'home',
      selected: false,
      isIcon: true,
    },
    {
      text: 'Bath',
      key: 'bath',
      selected: false,
      isIcon: true,
    },
    {
      text: 'More',
      key: 'more',
      selected: false,
      isIcon: true,
    }
  ];
  constructor() { }

  ngOnInit() {}

  changeFilter(value, i) {
    console.log(value, i);
    this.items.map(item => item.selected = false);
    this.items[i].selected = true;

    this.activeFilter.emit(this.items[i].key);
  }
}
